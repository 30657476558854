<template>
  <div>
    <h2 class="mb-4">Валюты</h2>

    <currencies-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <data-table
      url="currencies"
      item-key="alphaCode"
      clickable-rows
      :headers="headers"
      :table-params="tableParams"
      @click:row="editCurrency"
    >
      <template v-slot:[`item.isActive`]="{ item }">
        <div>{{ item.isActive | yesNo }}</div>
      </template>
    </data-table>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";

import CurrenciesFilters from "~/components/currencies/filters";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Alpha-код", value: "alphaCode", sortable: false },
        { text: "Название", value: "name", sortable: false },
        { text: "Активна", value: "isActive", sortable: false }
      ],
      tableParams: {
        isActive: true,
        isBaseCurrency: false
      }
    };
  },
  methods: {
    editCurrency(currency) {
      this.$router.push({ name: "currencies-alphaCode", params: { alphaCode: currency.alphaCode } });
    },
    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },
  components: {
    CurrenciesFilters
  }
};
</script>
